var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"review-detail-sidebar"},[_c('div',{staticClass:"review-section"},[_c('div',{staticClass:"review-section-header",style:(_vm.rowData.pickupPositive === null ? { color: 'gray' } : {})},[_c('p',{staticStyle:{"margin-right":"8px","margin-bottom":"5px"}},[_vm._v(" How was your pickup? ")]),_c('img',{class:`satisfaction-icon-${
          _vm.rowData.pickupPositive ? 'solid' : 'transparent'
        }`,attrs:{"src":require("@/assets/images/icon-satisfied.png")}}),_c('img',{class:`satisfaction-icon-${
          _vm.rowData.pickupPositive !== null && !_vm.rowData.pickupPositive
            ? 'solid'
            : 'transparent'
        }`,attrs:{"src":require("@/assets/images/icon-unsatisfied.png")}})]),_c('p',{staticClass:"review-text",style:(!_vm.rowData.pickupReview || _vm.rowData.pickupReview.length === 0
          ? { color: 'gray' }
          : {})},[_vm._v(" "+_vm._s(_vm.formatReview(_vm.rowData.pickupReview))+" ")])]),_vm._l((_vm.reviews),function(review){return _c('div',{key:review.name,staticClass:"review-section"},[_c('div',{staticClass:"review-section-header",style:(review.grayMessage ? { color: 'gray' } : {})},[_c('p',{staticStyle:{"margin-right":"8px","margin-bottom":"5px"}},[_vm._v(" "+_vm._s(review.message)+" ")]),(review.rating)?_c('StarRating',{attrs:{"read-only":"","rating":review.rating,"show-rating":false,"max-rating":5,"star-size":20,"round-start-rating":false,"border-width":0,"padding":3,"glow":0,"glow-color":"none","border-color":"none"}}):_vm._e()],1),_c('p',{staticClass:"review-text",style:(review.grayReview ? { color: 'gray' } : {})},[_vm._v(" "+_vm._s(_vm.formatReview(review.review))+" ")])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }