<template>
  <div class="review-detail-sidebar">
    <div class="review-section">
      <div
        class="review-section-header"
        :style="rowData.pickupPositive === null ? { color: 'gray' } : {}"
      >
        <p style="margin-right: 8px; margin-bottom: 5px">
          How was your pickup?
        </p>
        <img
          src="@/assets/images/icon-satisfied.png"
          :class="`satisfaction-icon-${
            rowData.pickupPositive ? 'solid' : 'transparent'
          }`"
        />
        <img
          src="@/assets/images/icon-unsatisfied.png"
          :class="`satisfaction-icon-${
            rowData.pickupPositive !== null && !rowData.pickupPositive
              ? 'solid'
              : 'transparent'
          }`"
        />
      </div>
      <p
        class="review-text"
        :style="
          !rowData.pickupReview || rowData.pickupReview.length === 0
            ? { color: 'gray' }
            : {}
        "
      >
        {{ formatReview(rowData.pickupReview) }}
      </p>
    </div>
    <div v-for="review in reviews" :key="review.name" class="review-section">
      <div
        class="review-section-header"
        :style="review.grayMessage ? { color: 'gray' } : {}"
      >
        <p style="margin-right: 8px; margin-bottom: 5px">
          {{ review.message }}
        </p>
        <StarRating
          v-if="review.rating"
          read-only
          :rating="review.rating"
          :show-rating="false"
          :max-rating="5"
          :star-size="20"
          :round-start-rating="false"
          :border-width="0"
          :padding="3"
          :glow="0"
          glow-color="none"
          border-color="none"
        />
      </div>
      <p
        class="review-text"
        :style="review.grayReview ? { color: 'gray' } : {}"
      >
        {{ formatReview(review.review) }}
      </p>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  components: {
    StarRating,
  },
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    reviews() {
      return [
        {
          name: 'vehicle-review',
          message: 'How was your vehicle(s)?',
          rating: this.rowData?.vehicleRating,
          review: this.rowData?.vehicleReview,
          grayMessage: !this.rowData.vehicleRating,
          grayReview:
            !this.rowData.vehicleReview ||
            this.rowData.vehicleReview.length === 0,
        },
        {
          name: 'driver-review',
          message: 'How was your driver(s)?',
          rating: this.rowData?.driverRating,
          review: this.rowData?.driverReview,
          grayMessage: !this.rowData.driverRating,
          grayReview:
            !this.rowData.driverReview ||
            this.rowData.driverReview.length === 0,
        },
        {
          name: 'operator-review',
          message: 'How was your operator(s)?',
          rating: this.rowData?.operatorRating,
          review: this.rowData?.operatorReview,
          grayMessage: !this.rowData.operatorRating,
          grayReview:
            !this.rowData.operatorReview ||
            this.rowData.operatorReview.length === 0,
        },
        {
          name: 'charterup-review',
          message: 'How was CharterUP?',
          rating: this.rowData?.charterupRating,
          review: this.rowData?.charterupReview,
          grayMessage: !this.rowData.charterupRating,
          grayReview:
            !this.rowData.charterupReview ||
            this.rowData.charterupReview.length === 0,
        },
      ]
    },
  },
  methods: {
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    formatReview(review) {
      return review ? `"${review}"` : 'No review'
    },
  },
}
</script>

<style lang="scss" scoped>
.review-detail-sidebar {
  height: 100%;
  width: 500px;
  padding: 40px;
}

.review-section {
  display: block;
  align-items: center;
  margin-bottom: 30px;
}

.review-section-header {
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: flex-start;
}

.satisfaction-icon-solid {
  width: 25px;
  height: 25px;
  margin: 0px 5px;
}

.satisfaction-icon-transparent {
  width: 25px;
  height: 25px;
  margin: 0px 5px;
  opacity: 0.5;
  filter: grayscale(1);
}

.review-text {
  font-size: 13px;
  font-weight: 500;
}
</style>
